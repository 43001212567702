import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RequiredNoSpaceValidator, EmailValidator } from './shared/directives/validator.directive';
import { AgmCoreModule } from '@agm/core';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { SlideshowModule } from 'ng-simple-slideshow';
import { NgxPaginationModule } from 'ngx-pagination'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { TourDateComponent } from './components/tour-date/tour-date.component';
import { TourdetailPreeventComponent } from './components/tourdetail-preevent/tourdetail-preevent.component';
import { TourdetailPosteventComponent } from './components/tourdetail-postevent/tourdetail-postevent.component';
import { FacultyComponent } from './components/faculty/faculty.component';
import { FacultyDetailsComponent } from './components/faculty-details/faculty-details.component';
import { RulesAndRegulationsComponent } from './components/rules-and-regulations/rules-and-regulations.component';
import { LiveStreamComponent } from './components/live-stream/live-stream.component';
import { DownloadMediaComponent } from './components/download-media/download-media.component';
import { ContactComponent } from './components/contact/contact.component';
import { ExecutiveTeamComponent } from './components/executive-team/executive-team.component';
import { FacultyService } from './services/faculty.service';
 import { HttpClientModule } from '@angular/common/http';
import { JoinOurTeamComponent } from './components/join-our-team/join-our-team.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { AppLoaderComponent } from './components/app-loader/app-loader.component';
import { DancerVerificationComponent } from './components/dancer-verification/dancer-verification.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ImportantComponent } from './components/important/important.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { HiringComponent } from './components/hiring/hiring.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TourDateComponent,
    TourdetailPreeventComponent,
    TourdetailPosteventComponent,
    FacultyComponent,
    FacultyDetailsComponent,
    RulesAndRegulationsComponent,
    LiveStreamComponent,
    DownloadMediaComponent,
    ContactComponent,
    ExecutiveTeamComponent,
    JoinOurTeamComponent,
    RequiredNoSpaceValidator,
    EmailValidator,
    JoinUsComponent,
    ThankyouComponent,
    AppLoaderComponent,
    DancerVerificationComponent,
    ImportantComponent,
    BlogComponent,
    BlogDetailsComponent,
    HiringComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDzts7e2X2qLZv0UyBDENuR3SJ5z26hpEE'
    }),
    SlideshowModule,
    CarouselModule.forRoot(),
    NgxPaginationModule,
    AgmJsMarkerClustererModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6Lca-iUUAAAAAAQ6T6vYEAp3YybZWpeKUXRJ5E8S',
    } as RecaptchaSettings,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
