import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Subscription } from 'rxjs';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { doAnimate } from 'src/app/shared/app.helpers';
import { TourDateService } from '../../services/tour-date.service';
import { TourDate } from '../../models/TourDate';
import { API_URL } from '../../shared/constants/url.constants';

declare var $: any;

@Component({
  selector: 'app-tour-date',
  templateUrl: './tour-date.component.html',
  styleUrls: ['./tour-date.component.scss'],
  providers: [TourDateService]
})
export class TourDateComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private tourdateService: TourDateService, private http: Http, private router: Router) { }
  eventlistdata: any = {
    allList: [],
    rhythmRegionalList: [],
    reverbNationalList: []
  };
  tourDate: TourDate;
  eventseasons: any = [];
  eventstates: any = [];
  selectedseason: any;
  eventmonthrange: any = [];
  EventFilters = {
    SeasonName: "",
    SeasonId: 0,
    EventType: "All",
    StateId: 0,
    StateName: "",
    Months: 0,
    MonthName: ""
  };
  months = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September',
    'October', 'November', 'December'
  ];
  eventType: string = 'ALL';
  isListView: boolean = true; // Default to List View
  latitude = 37.7749; // Default map center latitude
  longitude = -122.4194; // Default map center longitude
  zoom = 5; // Default zoom level
  markers: any[] = []; // Array to store all map markers
  ViewType: number = 1;
  url: any;
  index: any = 0;
  isLoading: boolean = false;
  mapStyles: any[] = [
    {
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }] // Light background
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }] // Hides default icons
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }] // Darker text for labels
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f5f5' }] // Matches background for text stroke
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }] // Light text for administrative labels
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }] // Subtle styling for POIs
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }] // Darker text for POI labels
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }] // Very light greenish tone for parks
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }] // Neutral text for park labels
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }] // White for roads
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }] // Dark gray for arterial road labels
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }] // Slightly highlighted highways
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }] // Dark gray for highway labels
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }] // Very light gray for transit
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }] // Neutral labels for transit stations
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }] // Light gray for water bodies
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }] // Subtle text for water labels
    }
  ];
  clusterStyles = [
    {
      url: 'assets/images/Reverb_Map_Pin_1.png',
      height: 50,
      width: 150,
      textColor: 'black',
      textSize: 15,
      fontWeight: 'bold',
    }
  ];
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    $(document).ready(() => {
      //open/close lateral filter
      $('.cd-filter-trigger').on('click', function () {
        triggerFilter(true);
      });
      $('.cd-filter .cd-close').on('click', function () {
        triggerFilter(false);
      });

      function triggerFilter($bool) {
        var elementsToTrigger = $([$('.cd-filter-trigger'), $('.cd-filter'), $('.cd-tab-filter'), $('.cd-gallery')]);
        elementsToTrigger.each(function () {
          $(this).toggleClass('filter-is-visible', $bool);
        });
      }
    });
    this.getseasonslist();
    this.getstateslist();
    this.getmonth();
    this.getTourDates();
  }

  getseasonslist() {
    this.eventseasons = [];
    this.tourdateService.GetSeasonlist().subscribe(seasondata => {
      if (seasondata != null) {
        seasondata.forEach(childObj => {
          childObj.current = false;
          if (childObj.selected)
            childObj.current = true;

          this.eventseasons.push(childObj);
        });
        this.EventFilters.SeasonId = this.eventseasons.find(x => x.selected == true).value;
        this.EventFilters.SeasonName = this.eventseasons.find(x => x.selected == true).text;

        // this.getseasoneventlist();
      }
    }, err => {
      console.log('err in tour date component', err);
    });
  }

  getstateslist() {
    this.eventstates = [];
    this.eventstates.push({ 'text': 'ALL', 'value': 0 });

    this.tourdateService.GetStatelist().subscribe(statedata => {
      if (statedata != null) {
        statedata.forEach(childObj => {
          this.eventstates.push(childObj);
        });
        this.EventFilters.StateId = 0;
        this.EventFilters.StateName = 'ALL';
      }
    }, err => {
      console.log('err in tour date component', err);
    });
  }

  getTourDates() {
    this.tourDate = new TourDate();
    this.isLoading = true;

    this.clearEventlistData();

    if (this.EventFilters.SeasonId == null && this.EventFilters.SeasonId == 0) {
      this.EventFilters.SeasonId = 0;
    }

    this.tourDate.seasonId = this.EventFilters.SeasonId;
    this.tourDate.eventType = this.eventType != undefined ? this.eventType : "ALL";
    this.tourDate.stateId = this.EventFilters.StateId;
    this.tourDate.month = this.EventFilters.Months;
    //doAnimate();
    this.markers = [];
    this.subscription = this.tourdateService.GetSeasonEventlist(this.tourDate).subscribe(responce => {
      this.isLoading = false;

      if (responce != null) {
        var res = responce.Response.Data;
        if (res.EventsList.AllList.length > 0) {
          res.EventsList.AllList.forEach(childObj => {
            var monthItem = {
              month: childObj.month,
              monthEventList: childObj.monthEventList
            };
            this.eventlistdata.allList.push(monthItem);
            this.addPinForEvent(childObj); // Add pin for this event
          });
        }
        if (res.EventsList.RhythmRegionalList.length > 0) {
          res.EventsList.RhythmRegionalList.forEach(childObj => {
            var monthItem = {
              month: childObj.month,
              monthEventList: childObj.monthEventList
            };
            this.eventlistdata.rhythmRegionalList.push(monthItem);
            this.addPinForEvent(childObj);
          });
        }
        if (res.EventsList.ReverbNationalList.length > 0) {
          res.EventsList.ReverbNationalList.forEach(childObj => {
            var monthItem = {
              month: childObj.month,
              monthEventList: childObj.monthEventList
            };
            this.eventlistdata.reverbNationalList.push(monthItem);
            this.addPinForEvent(childObj);
          });
        }
      }
    }, err => {
      console.log('err in tour date component', err);
      this.isLoading = false;
    });
  }

  monthNumToName(monthnum) {
    return this.months[monthnum - 1] || '';
  }

  getmonth() {
    this.eventmonthrange.push({ 'text': 'ALL', 'value': 0 });
    this.eventmonthrange.push({ 'text': 'JANUARY', 'value': 1 });
    this.eventmonthrange.push({ 'text': 'FEBRUARY', 'value': 2 });
    this.eventmonthrange.push({ 'text': 'MARCH', 'value': 3 });
    this.eventmonthrange.push({ 'text': 'APRIL', 'value': 4 });
    this.eventmonthrange.push({ 'text': 'MAY', 'value': 5 });
    this.eventmonthrange.push({ 'text': 'JUNE', 'value': 6 });
    this.eventmonthrange.push({ 'text': 'JULY', 'value': 7 });
    this.eventmonthrange.push({ 'text': 'AUGUST', 'value': 8 });
    this.eventmonthrange.push({ 'text': 'SEPTEMBER', 'value': 9 });
    this.eventmonthrange.push({ 'text': 'OCTOBER', 'value': 10 });
    this.eventmonthrange.push({ 'text': 'NOVEMBER', 'value': 11 });
    this.eventmonthrange.push({ 'text': 'DECEMBER', 'value': 12 });
    this.EventFilters.Months = 0;
    this.EventFilters.MonthName = "ALL";
  }

  clearEventlistData() {
    this.eventlistdata.allList = [];
    this.eventlistdata.rhythmRegionalList = [];
    this.eventlistdata.reverbNationalList = [];
  }

  clickSeason(_item) {

    this.eventseasons.forEach(childObj => {
      childObj.selected = false;
    });

    _item.selected = true;
    this.EventFilters.SeasonId = _item.value;
    this.EventFilters.SeasonName = _item.text;
    this.getTourDates();
  }

  clickMonth(_item) {
    this.EventFilters.Months = _item.value;
    this.EventFilters.MonthName = _item.text;
    this.getTourDates();
  }

  clickState(_item, event: Event) {
    let selectedstateText = event.target['options']
    [event.target['options'].selectedIndex].text;

    this.EventFilters.StateId = _item;
    this.EventFilters.StateName = selectedstateText;
    this.getTourDates();
  }

  onEventdetailClick($event, eventseoname, isEventEnd, eventType) {

    if (eventType == 2) {
      var redirectGrooveURL = API_URL.BASE_PATH_GROOVE + "tour-dates/" + eventseoname;
      window.open(redirectGrooveURL, '_blank');
      return;
    }

    $event.stopPropagation();

    if (isEventEnd == false) {
      doAnimate();
      setTimeout(() => {
        this.router.navigate(['/tour-dates', eventseoname]);
      }, 500);
    }
    else {
      doAnimate();
      setTimeout(() => {
        this.router.navigate(['/tour-dates', eventseoname]);
      }, 500);
    }
  }

  gotoRought(_url, _isHref) {
    doAnimate();
    setTimeout(() => {
      if (_isHref)
        window.open(_url, "_self");
      else {
        this.router.navigate([_url]);
      }
    }, 500);
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadEvent(eventType) {
    this.eventType = eventType;
    this.getTourDates();
  }

  ChangeViewType(viewType) {
    this.ViewType = viewType;
  }


  addPinForEvent(eventObj: any): void {
    if (eventObj.monthEventList && eventObj.monthEventList.length > 0) {
      eventObj.monthEventList.forEach((event: any) => {
        // Check if VenueList is available; if not, use EventCityLatLong
        const venueList = event.VenueList && event.VenueList.length > 0
          ? event.VenueList
          : [{ LatLong: event.EventCityLatLong, Name: `${event.City}, ${event.State}` }];

        venueList.forEach((venue: any) => {
          let latitude: number | null = null;
          let longitude: number | null = null;

          if (venue.LatLong) {
            const latLong = venue.LatLong.split(',');
            latitude = parseFloat(latLong[0]);
            longitude = parseFloat(latLong[1]);
          }

          // Ensure we have valid coordinates
          if (!latitude || !longitude) {
            //console.log(`Invalid LatLong for venue: ${venue.Name}. Skipping.`);
            return; // Skip adding the marker
          }

          // Set map's initial position if it's the first marker
          if (this.markers.length === 0) {
            this.latitude = latitude;
            this.longitude = longitude;
          }

          // Add marker with the available coordinates
          this.addMarker(latitude, longitude, venue, event);
        });
      });
    }
  }

  addMarker(latitude: number, longitude: number, venue: any, event: any): void {
    const marker = {
      latitude,
      longitude,
      iconUrl: {
        url: '/assets/images/Reverb_Map_Pin_1.png',
        scaledSize: { width: 150, height: 50 },
        anchor: { x: 25, y: 50 }, // Optional: Center the icon
      },
      showInfo: false,
      details: {
        eventName: `${event.City ? event.City + ', ' : ''}${event.Abbreviation ? event.Abbreviation + ' - ' : ''}${event.EventTypeName || ''}`,
        eventType: event.EventTypeName || '',
        startDate: event.StartDate || '',
        endDate: event.EndDate || '',
        venueDetails: `${venue.Name || ''}${venue.Address ? ', ' + venue.Address : ''}${venue.City ? ', ' + venue.City : ''}${venue.State ? ', ' + venue.State : ''}${venue.ZipCode ? ', ' + venue.ZipCode : ''}`,
        seoName: event.SeoName || '',
        isEventEnd: event.IsEventEnd || false, // Default to false if not provided
      }
    };

    // Push marker to the markers array
    this.markers.push(marker);
  }

  toggleInfoWindow(marker: any) {
    // Close all other info windows
    this.markers.forEach((m) => (m.showInfo = false));

    // Open the clicked marker's info window
    marker.showInfo = true;
  }
}
